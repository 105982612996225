import React, { useState,useRef,useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";
import "./Header.css";

const Header = ({darkBg=false}) => {
  const [menuState, setMenuState] = useState(false);
  const [changeArrowcolor, setChangeArrowColor] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenDesk, setIsDropdownOpenDesk] = useState(false);
  const navigate = useNavigate()
  const clickCountRef = useRef(0);
  const dropdownRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isDropdownOpenDesk) {
        if (!event.target.closest('.app')) {
          setIsDropdownOpenDesk(false);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownOpenDesk]);


const onClickService = (e) => {
    e.preventDefault();
    
      clickCountRef.current++;
      if (clickCountRef.current === 1) {
        setIsDropdownOpen(true);
      } 
      else if (clickCountRef.current === 2) {
        setMenuState(false)
        setIsDropdownOpen(false)
        navigate("/service");
        clickCountRef.current = 0;
      }
  };

  const menuClick = () => {
    setMenuState((prev) => !prev);
  };

  return (
    <div className={`header w-100`}
      style={darkBg ? {backgroundColor:"#232323"} : {}}
    >
      <div className="header-wrapper container">
        <nav className="navbar d-md-flex flex-md-row d-block">
          <nav className="navbar-container px-lg-0 px-2 py-2">
            <div className="nav-elements d-flex justify-content-between align-items-center px-lg-2">
              <input className="d-none" type="checkbox" id="menu-toggle" />
              <img src="/images/icons/nav-logo.png" className="nav-logo" onClick={()=>{navigate("/")}} style={{cursor:"pointer"}}/>
              <nav>
                <ul
                  className="nav-list d-lg-flex gap-xl-4 gap-lg-3 align-items-center d-none main-nav"
                  id="js-menu"
                >
                  <li>
                    <Link to={"/about"} className="nav-links font-play">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to={"/facility"} className="nav-links font-play">
                      Facility
                    </Link>
                  </li>

                  {/* <div className="navigation-dropdown" ref={dropdownRef}>
                  <li onMouseEnter={()=>{setIsDropdownOpenDesk(true)}} >

                      <div className="dropdown-trigger d-flex align-items-center" onMouseOver={()=>{setChangeArrowColor(true)}} onMouseOut={()=>{setChangeArrowColor(false)}}>
                          <Link to={"/"} className="nav-links font-play" onClick={()=>{setIsDropdownOpenDesk(false)}} style={{color:`${changeArrowcolor ? "#155A75":"white"}`}}>SERVICES</Link>
                          <img src={`/images/icons/drop-icon-${changeArrowcolor ? "b":"w"}.svg`} className={isDropdownOpenDesk ? "rotate-icon arrow-icon":"rotate-back arrow-icon" }/>
                      </div>
                      <div className={`dropdown-content ${isDropdownOpenDesk ? 'open' : 'none-delay'}`} onMouseEnter={()=>{setIsDropdownOpenDesk(true)}} onMouseLeave={()=>{setIsDropdownOpenDesk(false)}}>
              
                        <Link to={"/"} className="nav-links font-play" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                          <p className="mb-0">LINK 1</p>
                        </Link>
                        <Link to={"/"} className="nav-links font-play" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                          <p className="mb-0">LINK 2</p>
                        </Link>
                      </div>
                    </li>
                    </div> */}



                  <li>
                    <Link to={"/showing"} className="nav-links font-play">
                      
                      Showing
                    </Link>
                  </li>
                  <li>
                    <Link to={"/rider-development"} className="nav-links font-play">
                    Rider Development
                    </Link>
                  </li>
                  <li>
                    <Link to={"/team"} className="nav-links font-play">
                    Team
                    </Link>
                  </li>
                  <li>
                    <Link to={"/sales-and-leasing"} className="nav-links font-play">
                      Sales and Leasing
                    </Link>
                  </li>
                </ul>
              </nav>

              <label
                value="menu-toggle"
                onClick={menuClick}
                className="responsive-menu-btn d-lg-none d-inline"
              >
                <img
                  src="/images/icons/hamburger-menu.png"
                  className="img-fluid menu-btn"
                  alt="menu"
                />
              </label>

              <div className="nav-buttton ml-5 d-lg-flex d-none gap-3 extras-btns">
                {/* <a href='tel:905-841-9879' style={{textDecoration:"none"}}></a> */}
                  <button 
                  className="hero-genral-btn font-play"  
                  onClick={()=>{navigate("/contact")}} 
                  >
                    Contact Us
                  </button>
              </div>
            </div>
          </nav>

          {/* Dropdown in responsive mode */}
          <nav className="responsive-popup-menu w-100">
            <div className="py-3 d-lg-none" style={{ display: menuState ? "block" : "none" }}>
            <ul
              className="resp-nav-list d-lg-none w-100 gap-4 hs-dropdown [--trigger:hover] pl-4"
              style={{ display: menuState ? "block" : "none" }}
            >
              <li>
                <Link to={"/about"} className="nav-links font-play">
                About
                </Link>
              </li>
              <li>
                <Link to={"/facility"} className="nav-links font-play">
                Facility
                </Link>
              </li>
               {/* <li>
                <div className="navigation-dropdown">
                  <div className="dropdown-trigger d-flex align-items-center" style={{color:"black"}} onClick={onClickService} >
                      <a className="nav-links font-play" >SERVICES</a>
                      <img src='/images/icons/drop-icon-b.svg' className={isDropdownOpen ? "rotate-icon arrow-icon":"rotate-back arrow-icon" } />
                  </div>
                  <div className={`dropdown-content ${isDropdownOpen ? 'open' : 'd-none'}`} style={{top:"85%",left:"0%"}}>
                    
                    <Link to={"/"} className="nav-links font-play" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0">LINK 1</p>
                    </Link>
                    <Link to={"/"} className="nav-links font-play" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0">LINK 2</p>
                    </Link>
                  </div>
                </div>
                </li> */}
              <li>
                <Link to={"/showing"} className="nav-links font-play">
                Showing
                </Link>
              </li>
              <li>
                <Link to={"/rider-development"} className="nav-links font-play">
                Rider Development
                </Link>
              </li>
              <li>
                <Link to={"/team"} className="nav-links font-play">
                Team
                </Link>
              </li>
              <li>
                <Link to={"/sales-and-leasing"} className="nav-links font-play">
                Sales and Leasing
                </Link>
              </li>
            </ul>
            <div className="nav-buttton flex-wrap d-flex gap-4 extras-btns">
              {/* <a href='tel:905-841-9879' style={{textDecoration:"none"}}></a> */}
                <button 
                className="hero-genral-btn font-play" 
                onClick={()=>{navigate("/contact")}} 
                >
                    Contact Us
                </button>
            </div>
            </div>
          </nav>
        </nav>
      </div>
    </div>
  );
};

export default Header; 