import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Sponsor from '../component/Sponsor/Sponsor'
import InstaImageGallery from "../component/InstaImageGallery/InstaImageGallery"
import Footer from '../component/Footer/Footer'
import { Helmet } from 'react-helmet'

const salesHourses = [
  {
    imgSrc:"/images/creatives/svs-zorocco-hourse-sale.jpg",
    imgText:true,
    imgTitle:"SVS Zarocco",
    imgContent:"SVS Zarocco is a 9 y/o Warmblood mare (by Zarocco Blue).    She is brave, honest and careful, with experience up to 1.40m.   A delight to handle in the barn with a desire to please, she is suitable for an ambitious Junior wanting to move up to the bigger Jumper classes.",
    imgPrice:"Sale Price - Above $100K USD"
  },
  {
    imgSrc:"/images/creatives/khaleesi-hourse-sale.jpg",
    imgText:true,
    imgTitle:"TME Khaleesi",
    imgContent:"TME Khaleesi is a 7 y/o purpose-bred TB for the show ring (never raced).   This loveable mare is brave, fast and wants to win in the 0.90/1.0m classes.   With beautiful flatwork and a pleasing disposition, she's suitable for a capable Amateur looking to ribbon in the 0.90-1.0m Jumper divisions.",
    imgPrice:"Lease Price -    $10-20K USD\n Sale Price -      $30-40K USD"
  },
  {
    imgSrc:"/images/creatives/Top-Gun-hourse-sale.jpg",
    imgText:true,
    imgTitle:"TME Top Gun",
    imgContent:"TME Top Gun is a 14 y/o Morgan who wins the heart of everyone who rides him.   Safe and always willing to try his hardest for you, this handsome gelding is suitable for a capable rider stepping into shows up to 0.75m.",
    imgPrice:"Lease Price -    $5-10K USD"
  },
  {
    imgSrc:"/images/creatives/Cindy-Lou-hourse-sale.jpg",
    imgText:true,
    imgTitle:"TME Cindy Lou",
    imgContent:"TME Cindy Lou Who is an 8 y/o Quarter Horse mare with a fun, forward disposition.   Consistently earning ribbons in the 0.65-0.75m classes, Cindy Lou is suitable for a confident rider learning to show up to 0.75m.",
    imgPrice:"Lease Price -    $5-10K USD"
  },
  
]
const instaImgData = [
  {
    imgSrc:"/images/creatives/sales-img-gallery-1.jpg",
    imgText:false,
    imgTitle:"LOREM IPSUM",
    imgContent:"Lorem Ipsum is simply dummy"
  },
  {
    imgSrc:"/images/creatives/sales-img-gallery-2.jpg",
    imgText:false,
    imgTitle:"LOREM IPSUM",
    imgContent:"Lorem Ipsum is simply dummy"
  },
  {
    imgSrc:"/images/creatives/sales-img-gallery-3.jpg",
    imgText:false,
    imgTitle:"LOREM IPSUM",
    imgContent:"Lorem Ipsum is simply dummy"
  },
  {
    imgSrc:"/images/creatives/sales-img-gallery-4.jpg",
    imgText:false,
    imgTitle:"LOREM IPSUM",
    imgContent:"Lorem Ipsum is simply dummy"
  },
  
]

function SalesAndLease() {
    return (
        <>
        <Helmet>
          <title>Horse Sales & Leasing Services | North America & Europe</title>
          <meta name="description" content="Find your perfect horse for sale or lease. Browse our Instagram and Facebook for current horses available. Partnered with top establishments."/>
          
          {/* <link rel="canonical" href=""></link> */}
        </Helmet>
            <HeroBanner 
            subHeading=""
            Heading="SALES & LEASING"
            nextLineSpanHeading=""
            Paragraph="We have sourced many horses throughout north America and Europe for clients. Whether you are looking to purchase or lease we can find an excellent match for you."
            bgImg="/images/creatives/salesLease-hero-bg.jpg"
            btn={false}
          />
        <InstaImageGallery
          subHeading="horses available For Sale"
          imgData={salesHourses}
          disableAutoplay={true}
        />
        <InstaImageGallery
          heading={"Check out our Instagram and Facebook pages for horses currently available."}
          imgData={instaImgData}
        />
             
        <div className='pb-5'>
          <div>
            <p className='container text-center'>
              <h3 className='body-heading mx-auto' style={{fontSize:"25px",width:"90%"}}>contact us to inquire about current sales and lease horses</h3>
              <div className='d-flex flex-column flex-sm-row align-items-center justify-content-center gap-1 gap-sm-3'>
                <a href='tel:+1 (647) 967-9650' style={{textDecoration:"none"}}>
                  <button className='genral-btn mt-4 d-flex align-items-center gap-2 mx-auto mx-lg-0'>CALL NOW</button>
                </a>
                <a href='mailto:info@themeadowsequine.com' style={{textDecoration:"none"}}>
                  <button className='genral-btn mt-4 d-flex align-items-center gap-2 mx-auto mx-lg-0'>SEND MAIL</button>
                </a>
              </div>
            </p>
          </div>
        </div>
            <Sponsor/>
            <div style={{height:"20px"}}></div>
    
          <Footer
            newsletterSection={false}
          />
        </>
      )
}

export default SalesAndLease