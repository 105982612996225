import "./Testimonials.css"
import React,{useState} from "react";
import { Autoplay,Pagination  } from "swiper/modules";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import ImgModal from "../ImgModal/ImgModal";

const imgData = [
  "/images/creatives/img-gallery-1.jpg",
  "/images/creatives/img-gallery-2.jpg",
  "/images/creatives/img-gallery-3.jpeg",
  "/images/creatives/img-gallery-4.jpg",
  "/images/creatives/img-gallery-5.jpg",
  "/images/creatives/img-gallery-6.jpg",
  "/images/creatives/img-gallery-7.JPG",
  "/images/creatives/img-gallery-8.JPG",
  "/images/creatives/img-gallery-9.JPG",
  "/images/creatives/img-gallery-10.jpeg",
  "/images/creatives/img-gallery-11.jpg",
  "/images/creatives/img-gallery-12.JPG",
  "/images/creatives/img-gallery-13.jpg",
  "/images/creatives/img-gallery-14.jpg",
  "/images/creatives/img-gallery-15.JPG",
  "/images/creatives/img-gallery-16.JPG",
  "/images/creatives/img-gallery-17.JPG",
  "/images/creatives/img-gallery-18.JPG",

  "/images/gallery/img-gallery-19.jpg",
  "/images/gallery/img-gallery-20.JPG",
  "/images/gallery/img-gallery-21.JPG",
  "/images/gallery/img-gallery-22.jpeg",
  "/images/gallery/img-gallery-23.jpeg",
  "/images/gallery/img-gallery-24.jpeg",
  "/images/gallery/img-gallery-25.JPG",
  "/images/gallery/img-gallery-26.JPG",
  "/images/gallery/img-gallery-27.jpeg",
  "/images/gallery/img-gallery-28.jpeg",
  "/images/gallery/img-gallery-29.JPG",
  "/images/gallery/img-gallery-30.jpeg",
  "/images/gallery/img-gallery-31.jpg",
  "/images/gallery/img-gallery-32.JPG",
  "/images/gallery/img-gallery-33.JPG",
  "/images/gallery/img-gallery-34.JPG",
  "/images/gallery/img-gallery-35.JPG",
  "/images/gallery/img-gallery-36.JPG",
] 

function Testimonials() {
  const [modal, setModal] = useState(false);
  const [imgSrc , setImgSrc] = useState("")

  const toggleModal = (e) => {
    setImgSrc(e.target.id)
    setModal((prev)=>{
        return !prev
    });
};

  return (
    <div className="Testimonials-container standard-padding-space">
      {modal ? <ImgModal status={modal} showImg={imgSrc} allImages={imgData} closeModal={toggleModal} /> : <></>}
      <div className="w-100 container px-0 my-3">
    <div className=" d-flex align-items-center w-100">
      <div className="col-12 text-center px-0">
        <p className="sub-heading mb-2 justify-content-center d-flex aling-items-center gap-lg-4 gap-3"><div style={{height:"0",width:"50px",border:"1px solid #A1845D",margin:"auto 0" }}></div>OUR GALLERY<div style={{height:"0",width:"50px",border:"1px solid #A1845D",margin:"auto 0" }}></div></p>
        <h3 className="body-heading" style={{color:"white"}}>A TRIBUTE TO OUR RIDERS, COACHES AND HORSES</h3>
        {/* <p className="body-paragraph" style={{color:"white"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}


        <div className="container-fluid ">
          <div className="row card-list" style={{position:"relative"}}>
            <div className="col-12 ">
            <Swiper
              spaceBetween={20}
              pagination={true}
              breakpoints={{
                200:{
                  slidesPerView: 1,
                },
                1000: {
                  slidesPerView: 3,
                },
              }}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
              loop={true}
              autoplay={
                {
                  delay:2000,
                  disableOnInteraction:false
                }
              }
              // modules={[Autoplay,Pagination]}
              modules={[Autoplay]}
              observer ={true}
              observeParents={true}
              parallax={true}
              className="testimonials-list py-5"
              id="testimonial-cards-slider"
                  >
                    {
                      imgData.map((imgSrc,ind)=>{
                        return(<>
                          <SwiperSlide key={ind} >
                            <div
                              className="sec"
                              id={imgSrc} onClick={toggleModal}
                              style={{backgroundImage:`url(${imgSrc})`,backgroundRepeat:"no-repeat",backgroundSize:"cover"}}
                            >
                              {/* <img src={imgSrc} className="img-fluid" id={imgSrc} onClick={toggleModal} /> */}

                            </div>
                          </SwiperSlide>
                          </>)
                      })
                    }
              </Swiper>
            </div>
          </div>
        </div>
        
       
      </div>
      </div>
  </div>
</div>
  )
}

export default Testimonials