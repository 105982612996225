import React from "react";
import styles from "./IdvBlogSec.module.css";
import PopularBlogCard from "./PopularBlogCard";
import "./Card.css"
import { useNavigate } from "react-router-dom";

const IdvBlogSec = ({ text, blogsData,recentBlogs ,published_date }) => {

  const navigate = useNavigate()

  const convertToSlug = (str) => {
    return str.toLowerCase().replace(/\s+/g, "-");
  };

  const redirectUserToBlog = (id) => {
    if (!id) return;
    let slug = convertToSlug(id);

    navigate(`/blogs/${slug}`);
  };

  return (
    <div className={`${styles.main} main idvBlogMain standard-margin-space`}>
      
      <div className="container">
        <div className="row gy-4 justify-content-between">
            <div className="col-lg-6">
              {
                text != "" && 
              <div className={`para_main pt-4 pb-5 ${styles.para_main}`}  dangerouslySetInnerHTML={{ __html: text }}></div>
              }
                {blogsData.map((item) => (
                  <>
                    <h2 className="h2_main pb-2 body-heading" style={{fontWeight:700,textTransform:"uppercase"}}>{item.heading}</h2>
                    <div className={`para_main pb-5 body-paragraph ${styles.para_main}`} dangerouslySetInnerHTML={{ __html: item.paragraph }}></div>
                  </>
                ))}
            <div className="mx-auto" style={{width:"90%",border:"1px solid silver"}}></div>
              <div className={[styles.footer , "d-flex flex-column flex-md-row justify-content-center justify-content-md-between gap-4 my-4"]} style={{display:"flex"}} >  
              <div className="mx-auto mx-md-0">
                <div className={styles.socials}>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/meadowsequine/"
                  >
                    <img src="/images/icons/blog-icon-fb.svg" style={{width:"25px",height:"25px"}}/>
                  </a>
                  <a
                    target="_blank"
                  >
                    <img src="/images/icons/blog-icon-linked.svg" style={{width:"25px",height:"25px"}}/>
                  </a>
                  <a target="_blank">
                  <img src="/images/icons/blog-icon-twiter.svg" style={{width:"25px",height:"25px"}}/>
                  </a>
                  <a
                    target="_blank"
                  >
                    <img src="/images/icons/blog-icon-mail.svg" style={{width:"25px",height:"25px"}}/>
                  </a>
                  <a
                    target="_blank"
                  >
                    <img src="/images/icons/blog-icon-pin.svg" style={{width:"25px",height:"25px"}}/>
                  </a>
                </div>
              </div>
              <p className="para_mail mb-0">
                <strong>Published Date:</strong> <span>{published_date}</span>
              </p>
            </div>
            </div>
            <div className="col-lg-5" >
              <div className='popular-card-container p-3'>
              <h4 className='body-heading mb-4 mt-2 text-center text-xl-start' style={{fontSize:"20px" , color: "#1e1e1e" , fontWeight: 700}}>Most Popular Blogs</h4>
                    {
                        recentBlogs.map((ele)=>{
                            return(
                                <PopularBlogCard
                                    img={ele.logo}
                                    title={ele.blog_description}
                                    anchor={ele.slug_url}
                                    redirectionFunction={redirectUserToBlog}
                                />
                            )
                        })
                    }
                    </div>
            </div>
        </div>
        
       
         
        </div>
      </div>
  );
};

export default IdvBlogSec;
