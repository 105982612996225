import "./Footer.css"
import { Link,useNavigate,NavLink } from "react-router-dom";

function Footer({
    newsletterSection=true
}) {
    const navigate = useNavigate()
  return (
    <>
    <div className="form container">
        {newsletterSection && 
            <div className="container-fluid">
                <div className="form-container w-75 m-auto px-2">
                <div className="text-container text-center">
                    <p className="sub-heading" style={{color:"white"}}>Lorem Ipsum</p>
                    <h3 className="body-heading" style={{color:"white"}}>Join Our Newsletter</h3>
                </div>
                <div className='inp-container d-md-flex d-none align-items-center justify-content-between'>
                    <input type='text' placeholder='Your Email Address'/>
                    <button className='inp-btn'>Sign Up</button>
                </div>
                <div className='inp-container-resp d-md-none d-flex flex-column align-items-center justify-content-between'>
                    <input type='text' placeholder='Your Email Address'/>
                    <button className='inp-btn'>Sign Up</button>
                </div>
                </div>
            </div>
        }
    </div>
    <div className={`footer-container ${!newsletterSection && "pt-0 mt-5"}`}>
        <div className="container px-0">
        {/* newsletter Upper section */}
        <div className="upper-newsletter-container container-fluid mx-auto" >

            <div className="row d-flex align-items-start gy-5">
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="text-container">
                        <div className="newsletter-logo img-fluid mb-3">
                            <img  src="/images/icons/nav-logo.png" alt="" className="img-fluid" onClick={()=>{navigate("/")}} style={{cursor:"pointer"}}/>
                        </div>
                        <div className="text-content">
                            <p className="body-paragraph my-3" style={{color:"white"}}>Elite level coaching - NCCP High Performance Level 11 Coach <br/>*Full Service Boarding & Training Program <br/>*Rider Development Program <br/>*Horse Importing & Sales </p>
                        </div>
                        <div className="social-link d-flex gap-2 mt-3">
                            <a 
                                href="https://www.instagram.com/meadows.equine/"
                                target="blank"  
                            >
                                <img src="/images/icons/insta-icon.png" alt="" />
                            </a>
                            <a 
                                href="https://www.facebook.com/meadowsequine/"
                                target="blank"  
                            >
                                <img src="/images/icons/fb-icon.png" alt="" />
                            </a>
                        </div>
                    
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="page-links d-flex flex-md-row gap-3 flex-column align-items-md-start align-items-center text-center justify-content-md-evenly justify-content-center">
                        <div className="text-md-start text-center d-flex flex-column gap-3">
                            <NavLink to={"/"} className="anchor" exact activeClassName="active">
                                Home
                            </NavLink>
                            <NavLink to={"/about"} className="anchor" exact activeClassName="active">
                                About
                            </NavLink>
                            <NavLink to={"/facility"} className="anchor" exact activeClassName="active">
                                Facility
                            </NavLink>
                            <NavLink to={"/showing"} className="anchor" exact activeClassName="active">
                                Showing
                            </NavLink>
                            <NavLink to={"/rider-development"} className="anchor" exact activeClassName="active">
                                Rider Development
                            </NavLink>
                        </div>
                        <div className="text-md-start text-center d-flex flex-column  gap-3">
                            <NavLink to={"/team"} className="anchor" exact activeClassName="active">
                            Team
                            </NavLink> 
                            <NavLink to={"/sales-and-leasing"} className="anchor" exact activeClassName="active">
                            Sales & Leasing
                            </NavLink> 
                            <NavLink to={"/privacy-policy"} className="anchor" exact activeClassName="active">
                            Privacy Policy
                            </NavLink> 
                            {/* <p className="anchor mb-0">Terms & Conditions</p>
                            <p className="anchor mb-0">Privacy Policy</p>
                            <p className="anchor mb-0">Cookie Policy</p> */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-12">
                    <div className="info-container">
                       <div className="d-flex flex-column align-items-center justify-content-start gap-3">
                            <div className="d-flex align-items-center gap-2 w-100">
                                <img src="/images/icons/phone-location-icon.svg"/>
                                <div>
                                    <a href="tel:905-841-9879" className="body-heading info-content" style={{color:"white",textDecoration:"none",cursor:"pointer"}}>905-841-9879</a>
                                </div>
                            </div>
                            <div className="d-flex align-items-center gap-2  w-100">
                                <img src="/images/icons/mail-location-icon.svg"/>
                                <div>
                                <a href="mailto:info@themeadowsequine.com" className="body-heading info-content" style={{color:"white",textDecoration:"none",textTransform:"lowercase",cursor:"pointer"}}>info@themeadowsequine.com</a>
                                    {/* <p className="body-heading info-content mb-0">info@themeadowsequine.com</p> */}
                                </div>
                            </div>
                            <div className="d-flex align-items-start gap-2 w-100">
                                <img src="/images/icons/footer-location-icon.svg"/>
                                <div>
                                    
                                    <p className="body-heading info-content mb-0" style={{textTransform:"capitalize"}}>2495 Lloydtown Road, King City On L7B 1A3</p>
                                </div>
                            </div>
                            <p className="body-heading info-content mb-0" style={{textTransform:"initial"}}>We are a private facility and not open to the public. Guests are welcome to visit by appointment only. Please call or email us to arrange a day and time for your visit</p>
                       </div>
                    </div>
                </div>

            </div>
            
           
        </div>
        </div>
        {/* div for border lining */}
        <div className="my-5"></div>

        {/* Lower sections */}

        <div className="lower-section text-center py-2">
            <div className="lower-container container flex-column gap-1 flex-md-row justify-content-md-between justify-content-center">
                <p className="mb-0">Made With ❤️ By <a href="https://www.creativesquad.ca" target="blank">Creative Squad </a></p>
            <p className="mb-0">© 2024 The Meadows, All Rights Reserved.</p>
                
            </div>
        </div>
    </div>
    </>
  )
}

export default Footer