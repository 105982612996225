import React from 'react'
import "./OurTeam.css"

const teamMember = [
    {
        img:"/images/creatives/team-memb-1.png",
        title:"Carolyn Catton",
        breifContent:"Originally from Saskatchewan, Carolyn lived on the west coast for 12 years before re-locating to Toronto in 2014.",
        content:"Originally from Saskatchewan, Carolyn lived on the west coast for 12 years before re-locating to Toronto in 2014. She is an Equine Canada Level II Coach and has competed in everything from the hunter and young jumper divisions up to the Grand Prix and FEI 2* level. Carolyn has over 16 years of coaching experience bringing students from Short Stirrup through Children’s Jumpers and Junior Hunters. She has qualified riders for The Canadian Royal Winter Fair, Jump Canada Medal Finals, US Equitation competitions, and numerous international hunter and jumper competitions throughout the US. She formerly coached at Beth Underhill Stables, has been the Assistant Trainer to Butch Lu and Guy Thomas at Willow Tree Farms."
    },
    {
        img:"/images/creatives/team-memb-1.png",
        title:"Sophie Leblanc",
        breifContent:"Sophie has an eye for the hunters and has joined our coaching team in support of our show riders.",
        content:"Sophie has an eye for the hunters and has joined our coaching team in support of our show riders."
    },
    {
        img:"/images/creatives/team-memb-2.png",
        title:"Elizabeth Fahrner",
        breifContent:"As a lifelong rider with more than 10 years of Coaching experience, Elizabeth thrives on working with clients who want to learn and progress.",
        content:"As a lifelong rider with more than 10 years of Coaching experience, Elizabeth thrives on working with clients who want to learn and progress.  A generous, patient teaching style is her trademark and she loves to work with riders of every age and ability.   Elizabeth has a vast knowledge of horse care and as a certified Equine Massage Therapist she is a wonderful resource when assessing horse performance and overall wellness.   Away from the barn Elizabeth enjoys archery and spending time with her chocolate lab training in dog agility."
    },
    {
        img:"/images/creatives/team-memb-3.png",
        title:"Vicky Weaver",
        breifContent:"With more than 7 years Coaching experience, Vicky is excited to share her joy of competition with riders who are just embarking on their learn-to-show journey.",
        content:"With more than 7 years Coaching experience, Vicky is excited to share her joy of competition with riders who are just embarking on their learn-to-show journey.  Clients will benefit from her calm demeanour and practical approach to learning.  Previously an FEI level Groom and a modified Grand Prix rider, Vicky has a wealth of horse show experience and has also ridden and trained countless young horses in her hometown province of Quebec. In her spare time Vicky is passionate about fitness, sports and spending time with her dog and cats."
    },
]

function CardBox({
    img="",
    title="",
    content=""
})
{
    return(
        <div className='card-box text-center mx-auto'>
            <img src={img} className='person-img'/>
            <h4 className='body-heading title my-3' style={{fontSize:"30px"}}>{title}</h4>
            <p className='body-paragraph px-2 mb-3'>{content}</p>
        </div>
    )
}

function OurTeam() {
  return (
    <div className='OurTeam-container standard-padding-space'>
        <div className='container'>
            <div className='text-content text-center'>
                <p className='sub-heading mb-0 justify-content-lg-center justify-content-center d-flex aling-items-center gap-lg-4 gap-3'><div style={{height:"0",width:"50px",border:"1px solid #A1845D",margin:"auto 0" }}></div>OUR TEAM<div style={{height:"0",width:"50px",border:"1px solid #A1845D",margin:"auto 0" }}></div></p>
                {/* <h3 className='body-heading my-2 mt-3 '>LOREM IPSUM DELOR SETAMAT</h3> */}
                <p className='body-paragraph my-3 px-2'>Behind every champion is an exceptional team, and at The Meadows, our dedicated professionals play a pivotal role in shaping winning performances. Our team of expert grooms and barn staff goes above and beyond, delivering an unparalleled experience for both horses and riders. With an unwavering commitment to the highest standards of horse care, they ensure that our facilities remain immaculate, providing a welcoming environment for our clients.</p>
            </div>
            <div className='card-container container mt-5'>
                <div className='row gy-4'>
                    {
                        teamMember.map((ele)=>{
                            return(
                            <div className='col-lg-4 d-flex flex-column'>
                                <CardBox
                                    img={ele.img}
                                    title={ele.title} 
                                    content={ele.content}
                                />
                            </div>)
                        })
                    }
                    
                </div>

            </div>

        </div>
    </div>
  )
}

export default OurTeam