import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Sponsor from '../component/Sponsor/Sponsor'
import TextContent from '../component/TextContent/TextContent'
import Testimonials from "../component/Testimonials/Testimonials"
import Footer from '../component/Footer/Footer'
import OurTeam from '../component/OurTeam/OurTeam'
import { Helmet } from 'react-helmet'

const text2List = [
    {
      title:"Equitation Excellence",
      content:"At The Meadows, we emphasize proper equitation and horsemanship as the foundation of our program. Our tailored training focuses on flatwork, gymnastics, and exercises that ensure all students are well-prepared to excel in the equitation ring and beyond."
    },
    {
        title:"Jumpers Challenge",
        content:"The Jumper ring is where precision, quick thinking, and a well-rounded set of skills are honed. Our students experience success, from pony jumper to Grand Prix levels, as they develop accuracy and agility in the saddle."
    },
    {
        title:"Hunter Mastery",
        content:"With years of experience and a track record of winning results and successful sales across North America, The Meadows is a trusted name in hunters. We source, train, and match both young and proven hunters with our clients, resulting in victories from small ponies to hunter derbies."
    },
]

function RiderDevelopment() {
  return (
    <>
        <Helmet>
              <title>Rider Development Program at The Meadows: Tailored Equestrian Training</title>
              <meta name="description" content="Transition from lessons to shows seamlessly with The Meadows' Rider Development Program. Tailored equitation training for riders."/>
              
              {/* <link rel="canonical" href=""></link> */}
            </Helmet>
        <HeroBanner 
        subHeading=""
        Heading="RIDER DEVELOPMENT"
        nextLineSpanHeading=""
        Paragraph="Embark on the next exciting chapter of your riding journey with The Meadows' Rider Development Program."
        bgImg="/images/creatives/rider-hero-bg.jpg"
        btn={false}
      />

<div className='left-gradient'>
      <TextContent
         subHeading=""
         heading= "Rider Development Program"
         content={"Tailored for riders making the transition from lesson to show programs, we offer a supportive environment to ease you into the challenges of showing. As you progress, you have the opportunity to step up to part board and, when ready to hit the show circuit, consider a part or full lease\nOur program boasts a fantastic array of experienced show horses, ensuring you find the perfect partner for your next equestrian adventure. Your ideal horse companion awaits—come discover the possibilities with us!"}
         btn={true}
         btnText="CALL NOW"
         btnRefTel={true}
         btnRef='/'
         shiftRow={true}
         img="/images/creatives/rider-text-1.png"
         reviewContent={false}
         btnImg={false}
         />

      </div>
      <div className='right-gradient'>

        <TextContent
          subHeading=''
          heading={"Part Board Program"}
          content={"In our part board program, you share the cost of horse care and lessons, making it ideal for riders ready to graduate from a lesson program. Perfect for those seeking more social interaction, additional riding time, or exploring the excitement of showing on their path to the A Circuit."}
          shiftRow={false}
          img={"/images/creatives/rider-text-2.png"}

        />
        
      {/* <OurTeam/> */}
      <Sponsor/>
        
      <TextContent
         subHeading=""
         subHeadingLine={false}
         heading= "Explore our Rider Development Program"
         content=""
         btn={false}
         shiftRow={true}
         img="/images/creatives/rider-text-3.png"
         reviewContent={false}
         btnText={"CALL NOW"}
         btnImg={false}
         dropDown={true}
          btnRefTel={false}  
          btnEnd={true}
         dropDownList={text2List}
         />

         </div>
      <div className='bottom-gradient'>
      <Testimonials/>
      
      <Footer
        newsletterSection={false}
      />
      </div>
    </>
  )
}

export default RiderDevelopment