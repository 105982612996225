import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Sponsor from '../component/Sponsor/Sponsor'
import TextContent from '../component/TextContent/TextContent'
import Testimonials from "../component/Testimonials/Testimonials"
import Footer from '../component/Footer/Footer'
import OurCommitment from '../component/OurCommitment/OurCommitment'
import { Helmet } from 'react-helmet'

const text1List = [
  "<p class='body-paragraph'>Join our multi-year High-Performance Athlete Development Program, aiming for international competitions at an <b>A Circuit</b> level.</p>",
  "<p class='body-paragraph'>Create a plan to bring along a young horse to show.</p>",
  "<p class='body-paragraph'>Strive for competitiveness at a specific level with our <a href='/rider-development' style='color:#575757; text-decoration:none;'><b>Rider Development Program</b></a></p>",
];

const text2List = [
    {
        title:"Producing Well-Rounded Athletes",
        content:"Our commitment to producing well-rounded athletes extends beyond the arena. The Meadows fosters a family-friendly and team-encompassing atmosphere, providing the ultimate learning environment and community for athletes, parents, horse owners, staff, and coaches."
    },
    {
        title:"All-Inclusive Full Training Program",
        content:"Immerse yourself in our all-inclusive full training program, featuring a 5-day-a-week schedule. With a combination of private or semi-private lessons and professional training rides, our program is tailored to meet the needs of each equine athlete. At The Meadows, we are dedicated to nurturing the potential of both riders and their horses, fostering growth in a supportive and enriching environment."
    },
    {
        title:"Supportive, Team-Based Approach",
        content:"At The Meadows, we believe in the strength of community. Our riders, along with their families and friends, form a supportive network. Recognizing that success in this sport requires a village, we encourage everyone to excel within our family-oriented environment."
    },
    {
        title:"Promoting Good Sportsmanship",
        content:"We foster an atmosphere of respect, support, and good sportsmanship among teammates, opponents, coaches, and officials. Valuing the rights, feelings, and property of others, we strive to maintain a fun and healthy environment for everyone."
    },
]

const text3list = [
  "Develop a comprehensive plan at the beginning of the year, outlining goals and budgets.",
  "Your coach will guide you in choosing the right shows and classes.",
  "Our staff will handle the organization of transportation for horses and equipment, ensuring a smooth transition at the show.",
  "Arrive at the ringside ready to compete, as we are full service at shows.  We take care of delivering your horse, allowing you to focus on learning and walking courses.",
];

function Showing() {
    return (
        <>
            <Helmet>
              <title>Show Potential Unlocked | Join Our Tailored Show Team</title>
              <meta name="description" content='Embark on your show journey with tailored guidance from experienced professionals. Our turnkey approach ensures a hassle-free experience, promoting good sportsmanship. Join us!'/>
              
              {/* <link rel="canonical" href=""></link> */}
            </Helmet>
            <HeroBanner 
            subHeading=""
            Heading="Unlock Your Show Potential"
            nextLineSpanHeading=""
            Paragraph="Join Our Show Team"
            bgImg="/images/creatives/showing-hero-bg.jpg"
            btn={false}
            heightAuto={false}
          />
    
    <div className='left-gradient'>
          <TextContent
             subHeading=""
             heading= "Tailored Guidance"
             content={"Embark on your show journey with a tailored plan that aligns with your unique goals and schedule. Whether you're competing for pure enjoyment or have ambitious aspirations in the Hunter, Equitation, or Jumper ring, we collaborate to develop a budget, plan, and strategy that suits your objectives."}
             btn={false}
             btnText=""
             btnRefTel={false}
             shiftRow={true}
             img="/images/creatives/showing-text-1.png"
             reviewContent={false}
             btnImg={false}
             customList={true}
             customListData={text1List}
             />
    
          </div>
          <div className='left-gradient-upper'>
            
          <TextContent
             subHeading=""
             heading= "Our Commitment"
             content=""
             btn={false}
             shiftRow={false}
             img="/images/creatives/showing-text-2.png"
             reviewContent={false}
             btnText={"CALL NOW"}
             btnImg={false}
             dropDown={true}
             dropDownList={text2List}
             />
          <div className='right-gradient'>
          <Sponsor/>
          <TextContent
             subHeading=""
             heading= "Turnkey Show Experience"
             content="Experience a hassle-free show journey with our turnkey approach:"
             btn={false}
             shiftRow={true}
             img="/images/creatives/showing-text-3.png"
             reviewContent={false}
             btnImg={false}
             dropDown={false}
             bulletList={true}
             bulletListData={text3list}
             />
             </div>
             </div>
          <div className='bottom-gradient'>
          <div className='left-gradient-upper'>
            {/* <OurCommitment/> */}
          <Testimonials/>
          </div>
          <Footer
            newsletterSection={false}
          />
          </div>
        </>
      )
}

export default Showing