import React from 'react'
import Sponsor from '../component/Sponsor/Sponsor'
import Footer from '../component/Footer/Footer'
import HeroBannerDark from '../component/Common Banner Component Dark/HeroBannerDark'

function Contact() {
    return (
        <>
        <div className='left-gradient-upper'>
            <HeroBannerDark
            subHeading=""
            Heading="THE MEADOWS EQUINE"
            nextLineSpanHeading=""
            Paragraph={"Elite level coaching - NCCP High Performance Level 11 Coach *Full service boarding & training program *Young horse importing & development *Horse Sales \n We are a private facility and not open to the public. Guests are welcome to visit by appointment only. Please call or email us to arrange a day and time for your visit"}
            socialLinks={true}
            serviceForm={true}
            btn={false}
          />
          <Sponsor/>
          <div style={{height:"10px"}}></div>
          </div>
          <Footer
            newsletterSection={false}
          />
        </>
          )
}

export default Contact