import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Sponsor from '../component/Sponsor/Sponsor'
import TextContent from '../component/TextContent/TextContent'
import Testimonials from "../component/Testimonials/Testimonials"
import Footer from '../component/Footer/Footer'
import ThoughtfulAmenities from '../component/ThoughtfulAmenities/ThoughtfulAmenities'
import RidingSpaces from '../component/RidingSpaces/RidingSpaces'
import { Helmet } from 'react-helmet'

const textDropDown = [
  {
    title:"Tack Rooms",
    content:"Enjoy our large, heated tack room with 30 floor-to-ceiling lockers, a separate equipment wash station, commercial-grade laundry, and a heated washroom."
  },
  {
    title:"Viewing Room & Cabana",
    content:"Experience comfort in our heated and air-conditioned viewing room with TV, Wi-Fi, kitchenette, and washroom. Alternatively, enjoy outdoor viewing at our shaded cabana."
  },
  {
    title:"Paddocks",
    content:"Our paddocks provide fresh water 24/7, green grass, and a rotation system for optimal grazing. With brand new three-board fencing, safety is a priority."
  },
  {
    title:"The Barn",
    content:"Our stable boasts a dust-free environment with extra ceiling height, wide aisles, LED lighting, and windows for natural light. Double hallway access ensures convenience."
  },
  {
    title:"Stalls",
    content:"We offer 28 large 12’ x 12’ stalls with extra-tall ceilings, large windows, and individual water monitoring. Inside and exterior wash stalls are equipped with temperature-controlled water and heating systems for winter bathing"
  },
]

function Facility() {
    return (
        <>
            <Helmet>
              <title>Premier Riding Facility in King City | The Meadows</title>
              <meta name="description" content='Discover The Meadows, a top-quality riding facility conveniently located just north of Toronto. Explore our thoughtfully designed amenities for riders and horses.'/>
              
              {/* <link rel="canonical" href=""></link> */}
            </Helmet>
            <HeroBanner 
            subHeading=""
            Heading="OUR FACILITY"
            nextLineSpanHeading=""
            Paragraph={"Beautiful space nestled just north of Toronto, The Meadows spans over 55 acres of Oak Ridges moraine and greenbelt, surrounded by the tranquility of nature."}
            bgImg=""
            btn={false}
            bgEffect={false}
            heightAuto={false}
            bgVideo={true}
          />
    
    <div className='left-gradient'>
          <TextContent
             subHeading="TOP QUALITY RIDING FACILITY"
             heading= "CONVENIENTLY LOCATED"
             content={"Twenty five minutes north of Toronto in King City Township, our beautiful property features two stables and four riding rings. Easily accessible from Highway #400, our location is just minutes away from Newmarket, Aurora, Richmond Hill, Vaughan, Markham, and Stouffville. The Meadows provides a serene escape for riders and nature enthusiasts, offering a convenient and picturesque setting for all."}
             btn={true}
             btnText="CALL NOW"
             btnRefTel={true}
             shiftRow={true}
             img="/images/creatives/facility-text-1.png"
             reviewContent={false}
             btnImg={false}
             subHeadingLine={false}
             bulletList={false}
             />
    
          </div>
          <div className='left-gradient-upper'>
            {/* <ThoughtfulAmenities/> */}
            <Sponsor/>

          
          <div className='right-gradient'>
            <RidingSpaces/>
            
          
          <TextContent
            subHeading=""
            heading= "Thoughtful Amenities"
            content="Every detail has been carefully considered to create a space that is not only functional but also visually stunning. Our modern amenities cater to both riders and horses, ensuring a top-tier riding experience"
            btn={false}
            shiftRow={false}
            img="/images/creatives/fac-text-2.png"
            reviewContent={false}
            btnImg={true}
            dropDown={true}
            dropDownList={textDropDown}
            /> 
         
             </div>
             </div>
          <div className='bottom-gradient'>
          <div className='left-gradient-upper'>
          <Testimonials/>
          </div>
          <Footer
            newsletterSection={false}
          />
          </div>
        </>
      )
}

export default Facility