import React from "react";
import HeroBanner from "../component/Common Banner Component/HeroBanner";
import Sponsor from "../component/Sponsor/Sponsor";
import TextContent from "../component/TextContent/TextContent";
import Testimonials from "../component/Testimonials/Testimonials";
import Footer from "../component/Footer/Footer";
import OurTeam from "../component/OurTeam/OurTeam";
import ScrollingTextContent from "../component/ScrollingTextContent/ScrollingTextContent";
import OurTeamSlide from "../component/OurTeamSlide/OurTeamSlide";
import { Helmet } from 'react-helmet'

function Team() {
  return (
    <>
    <Helmet>
      <title>Expert Team Crafting Winning Performances | The Meadows</title>
      <meta name="description" content="Meet our dedicated professionals behind top-tier horse training in Ontario. Learn about our coaches, riders, and our commitment to excellence."/>
      
      {/* <link rel="canonical" href=""></link> */}
    </Helmet>
      <HeroBanner
        subHeading=""
        Heading="TEAM"
        nextLineSpanHeading=""
        Paragraph="Our dedicated professionals play a pivotal role in shaping winning performances."
        bgImg="/images/creatives/team-hero-bg.jpg"
        btn={false}
      />

      <div className="left-gradient">
        {/* <TextContent
         subHeading=""
         heading= "BEHIND EVERY CHAMPION IS A SUCCESSFUL TEAM"
         content={"At The Meadows, we have crafted a leading training program in Ontario for ambitious child and junior riders, earning a well deserved reputation for producing top-tier, well-trained, and impeccably presented horses in the show ring.\nOur team of expert grooms and barn staff goes above and beyond, delivering an unparalleled experience for both horses and riders. With an unwavering commitment to the highest standards of horse care, they ensure that our facilities remain immaculate, providing a welcoming environment for our clients.\nWe prioritize the health and wellness of our horses, and we are privileged to collaborate with highly skilled professionals in veterinary medicine, farriery, and other essential equine care services such as chiropractic care, physiotherapy, and massage. Our commitment to excellence extends to our team selection, as we carefully choose and employ only the most qualified individuals who share our passion for their work and are dedicated to upholding the same elevated standards of care that define The Meadows"}
      
         btn={false}
         shiftRow={false}
         img="/images/creatives/team-text-1.png"
         reviewContent={false}
         btnImg={false}
         /> */}

        <ScrollingTextContent
          shiftRow={false}
          subHeading=""
          heading="BEHIND EVERY CHAMPION IS A SUCCESSFUL TEAM"
          content={
            "At The Meadows, we have crafted a leading training program in Ontario for ambitious child and junior riders, earning a well deserved reputation for producing top-tier, well-trained, and impeccably presented horses in the show ring.\nOur team of expert grooms and barn staff goes above and beyond, delivering an unparalleled experience for both horses and riders. With an unwavering commitment to the highest standards of horse care, they ensure that our facilities remain immaculate, providing a welcoming environment for our clients.\nWe prioritize the health and wellness of our horses, and we are privileged to collaborate with highly skilled professionals in veterinary medicine, farriery, and other essential equine care services such as chiropractic care, physiotherapy, and massage. Our commitment to excellence extends to our team selection, as we carefully choose and employ only the most qualified individuals who share our passion for their work and are dedicated to upholding the same elevated standards of care that define The Meadows."
          }
          img="/images/creatives/team-text-first.png"
        />

        <Sponsor />
        <OurTeamSlide />
        {/* <div className='standard-padding-space'>
        <div className='container'>
            <div className='text-content text-center'>
                <p className='sub-heading mb-0 justify-content-lg-center justify-content-center d-flex aling-items-center gap-lg-4 gap-3'>
                    <div style={{height:"0",width:"50px",border:"1px solid #A1845D",margin:"auto 0" }}></div>
                    TEAM
                    <div style={{height:"0",width:"50px",border:"1px solid #A1845D",margin:"auto 0" }}></div>
                </p>
              
                <h3 className='body-heading my-2 mt-3 '>
                  BEHIND EVERY CHAMPION IS A SUCCESSFUL TEAM
                </h3>
                <p className='body-paragraph my-3 px2'>
                  At The Meadows, we have crafted a leading training program in Ontario for ambitious child and junior riders, earning a well deserved reputation for producing top-tier, well-trained, and impeccably presented horses in the show ring.
                </p>
                <p className='body-paragraph my-3 px2'>
                  Our team of expert grooms and barn staff goes above and beyond, delivering an unparalleled experience for both horses and riders. With an unwavering commitment to the highest standards of horse care, they ensure that our facilities remain immaculate, providing a welcoming environment for our clients.
                </p>
                <p className='body-paragraph my-3 px2'>
                We prioritize the health and wellness of our horses, and we are privileged to collaborate with highly skilled professionals in veterinary medicine, farriery, and other essential equine care services such as chiropractic care, physiotherapy, and massage. Our commitment to excellence extends to our team selection, as we carefully choose and employ only the most qualified individuals who share our passion for their work and are dedicated to upholding the same elevated standards of care that define The Meadows
                </p>

            </div>

        </div>

    </div> */}
        {/* <TextContent
         subHeading="Head Coach & Trainer"
         heading= "Carolyn Catton"
         content={"Originally from Saskatchewan, Carolyn lived on the west coast for 12 years before re-locating to Toronto in 2014. She is an Equine Canada Level II Coach and has competed in everything from the hunter and young jumper divisions up to the Grand Prix and FEI 2* level. Carolyn has over 16 years of coaching experience bringing students from Short Stirrup through Children’s Jumpers and Junior Hunters. She has qualified riders for The Canadian Royal Winter Fair, Jump Canada Medal Finals, US Equitation competitions, and numerous international hunter and jumper competitions throughout the US. She formerly coached at Beth Underhill Stables, has been the Assistant Trainer to Butch Lu and Guy Thomas at Willow Tree Farms."}
         btn={false}
         shiftRow={true}
         img="/images/creatives/team-text-1.png"
         reviewContent={false}
         btnImg={false}
         />

      </div>
      <div className='left-gradient-upper'>
      <TextContent
         subHeading="Assistant Coach"
         heading= "Sophie Leblanc"
         content={"Sophie has an eye for the hunters and has joined our coaching team in support of our show riders."}
         shiftRow={false}
         img="/images/creatives/team-text-2.png"
         reviewContent={false}
         btn={false}
         dropDown={false}
         />

      <TextContent
         subHeading="Coach"
         heading= "Elizabeth Fahrner"
         content={"As a lifelong rider with more than 10 years of Coaching experience, Elizabeth thrives on working with clients who want to learn and progress.  A generous, patient teaching style is her trademark and she loves to work with riders of every age and ability.   Elizabeth has a vast knowledge of horse care and as a certified Equine Massage Therapist she is a wonderful resource when assessing horse performance and overall wellness.   Away from the barn Elizabeth enjoys archery and spending time with her chocolate lab training in dog agility."}
         shiftRow={true}
         img="/images/creatives/team-text-0.png"
         reviewContent={false}
         btn={false}
         dropDown={false}
         />
      <div className='right-gradient'>
        
      <TextContent
         subHeading="Coach Rider Development Program"
         heading= "Vicky Weaver"
         content={"With more than 7 years Coaching experience, Vicky is excited to share her joy of competition with riders who are just embarking on their learn-to-show journey.  Clients will benefit from her calm demeanour and practical approach to learning.  Previously an FEI level Groom and a modified Grand Prix rider, Vicky has a wealth of horse show experience and has also ridden and trained countless young horses in her hometown province of Quebec. In her spare time Vicky is passionate about fitness, sports and spending time with her dog and cats."}
         shiftRow={false}
         img="/images/creatives/team-text-3.png"
         reviewContent={false}
         btn={false}
         dropDown={false}
         subHeadingLine={false}
         /> */}

      </div>
      {/* </div> */}
      <div className="bottom-gradient">
        <Testimonials />

        <Footer newsletterSection={false} />
      </div>
    </>
  );
}

export default Team;
