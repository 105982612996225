import React,{useState} from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import PopularBlogs from "../component/PopularBlogs/PopularBlogs"
import Footer from '../component/Footer/Footer'

const catagory = [
    {
        name:"All",
        num:0
    },
    {
        name:"Lorem",
        num:2
    },
    {
        name:"Lorem",
        num:3
    },
    {
        name:"Lorem",
        num:4
    },
    {
        name:"Lorem",
        num:5
    },
    {
        name:"Lorem",
        num:6
    }
]

function Blogs() {
    const [selCatagory,setSelCatagory] = useState({
        name:"All",
        num:0
    })


    
    return (
        <>
            
            <HeroBanner 
            subHeading=""
            Heading="NEWS & BLOGS"
            nextLineSpanHeading=""
            Paragraph="At The Meadows, we have crafted a leading training program in Ontario for ambitious child and junior riders, earning a well deserved reputation for producing top-tier, well-trained, and impeccably presented horses in the show ring."
            bgImg="/images/creatives/blog-hero-bg.jpg"
            btn={false}
          />
    
    <div className='left-gradient'>
    <div className='blog-Catagory standard-padding-space'>
            <div className='container'>
                <div className='main-container' style={{borderRadius:"50px"}}>
                    <div 
                        className='row gy-2 main-catagory-container mx-3 mx-lg-0' 
                    >
                        {
                            catagory.map((ele)=>{
                                return(
                                <div 
                                    className={`col-xxl-2 col-lg-4  catagory-container ${ele.num == selCatagory.num ? "active" : ""} `}
                                    onClick={()=>{setSelCatagory(ele)}}    
                                >
                                    <p className='body-heading mb-0' >{ele.name}</p>
                                </div>
                                )
                            })
                        }
                        
                    </div>

                </div>

            </div>

        </div>
       <PopularBlogs
            selectedCatagory={selCatagory.num}
       />
 
         
          </div>
          
          <div className='bottom-gradient'>
          
          <Footer
            newsletterSection={false}
          />
          </div>
        </>
      )
}

export default Blogs