import "./InstaImageGallery.css"
import React from "react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

function Testimonials({
  subHeading = null,
  imgData,
  heading = "",
  disableAutoplay = false
}) {

  const handlePrevButtonClick = () => {
    const swiperInstance = document.querySelector('#testimonial-cards-slider').swiper;
    swiperInstance.slidePrev();
  };
  
  const handleNextButtonClick = () => {
    const swiperInstance = document.querySelector('#testimonial-cards-slider').swiper;
    swiperInstance.slideNext();
  };

  return (
    <div className="InstaImageGallery-container standard-padding-space">
      <div className="w-100 container px-0 my-3">
        <div className="d-flex align-items-center w-100">
          <div className="col-12 text-center px-0">
            {subHeading &&
              <p className="body-heading mb-2 justify-content-center d-flex align-items-center gap-lg-4 gap-3" style={{ color: "#A1845D" }}>
                <div style={{ height: "0", width: "50px", border: "1px solid #A1845D", margin: "auto 0" }} className="d-none d-lg-block"></div>
                {subHeading}
                <div style={{ height: "0", width: "50px", border: "1px solid #A1845D", margin: "auto 0" }} className="d-none d-lg-block"></div>
              </p>
            }
            <h3 className="body-heading mx-auto" style={{ fontSize: "25px", width: "90%" }}>{heading}</h3>
            <div className="container-fluid">
              <div className="row card-list" style={{ position: "relative" }}>
                <div className="col-12">
                  {
                    disableAutoplay &&
                    <div className="swiper-button-prev swiper-button d-lg-block d-none" onClick={handlePrevButtonClick}></div>
                  }
                  <Swiper
                    spaceBetween={20}
                    pagination={true}
                    breakpoints={{
                      200: {
                        slidesPerView: 1,
                      },
                      1000: {
                        slidesPerView: 3,
                      },
                    }}
                    navigation={disableAutoplay ? false : { 
                      nextEl: '.swiper-button-next',
                      prevEl: '.swiper-button-prev',
                    }}
                    loop={true} 
                    autoplay={disableAutoplay ? false : { 
                      delay: 3500,
                      disableOnInteraction: false
                    }}
                    modules={[Autoplay, Pagination, Navigation]}
                    observer={true}
                    observeParents={true}
                    parallax={true}
                    className="testimonials-list py-5"
                    id="testimonial-cards-slider"
                  >
                    
                    {
                      imgData.map((imgD, ind) => {
                        return (
                          <SwiperSlide key={ind}>
                            <div className="instaCardBox">
                              <img src={imgD.imgSrc} className="img-fluid" alt="slider images"/>
                              {imgD.imgText &&
                                <div className="content-box px-3 pb-3">
                                  <h4 className="title mt-3">{imgD.imgTitle}</h4>
                                  <p className="para body-paragraph ">{imgD.imgContent}</p>
                                  {imgD.imgPrice.split('\n').map((paragraph, index) => <p key={index} className='para body-paragraph my-2'>{paragraph}</p>)}
                                </div>
                              }
                            </div>
                          </SwiperSlide>
                        )
                      })
                    }
                  </Swiper>
                  {
                    disableAutoplay &&
                    <div className="swiper-button-next swiper-button d-lg-block d-none" onClick={handleNextButtonClick}></div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonials;
