import React from 'react'
import "./FacilitySection.css"
import { useNavigate } from 'react-router-dom'

function FacilitySection() {
    const navigate = useNavigate()
  return (
    <div className='facility-container standard-padding-space'>
        <div className='container py-0'>
            <div className={`text-content text-center`}>
                <p className='sub-heading mb-0 justify-content-center d-flex aling-items-center gap-lg-4 gap-3' style={{color:"white"}}><div style={{height:"0",width:"50px",border:"1px solid white",margin:"auto 0" }}></div>OUR FACILITIES<div style={{height:"0",width:"50px",border:"1px solid white",margin:"auto 0" }}></div></p>
                <div className=''>
                {/* <h3 className='body-heading my-2 mt-3 ' style={{color:"white"}}>Nestled just north of Toronto</h3> */}
                <div className=''>
                    <p className='body-paragraph mt-3' style={{color:"white"}}>Nestled just north of Toronto, The Meadows spans over 55 acres of Oak Ridges moraine and greenbelt, surrounded by the tranquility of nature. Conveniently located just 25 minutes north of Toronto in King City Township, our beautiful property features two stables and four riding rings.</p>
                </div>
               
                <button className='genral-btn mt-4 d-flex align-items-center gap-2 mx-auto' style={{borderColor:"white",color:"white"}} onClick={()=>{navigate("/facility")}}>LEARN MORE <img src='/images/icons/genral-btn-arrow-w.png'/></button>
                </div>
            </div>
            <div className='images-section d-flex flex-column flex-lg-row align-items-center justify-content-center gap-4'>
                <div className='row gy-4'>
                    <div className='col-lg-4 d-flex justify-content-center'>
                    <img src='/images/creatives/facility-img-1.jpg' className='img-fluid'/>
                    </div>
                    <div className='col-lg-4 d-flex justify-content-center'>
                    <img src='/images/creatives/facility-img-2.jpg' className='img-fluid'/>
                    </div>
                    <div className='col-lg-4 d-flex justify-content-center'>
                    <img src='/images/creatives/facility-img-3.jpg' className='img-fluid'/>
                    </div>
                </div>
                

            </div>
        </div>

    </div>
  )
}

export default FacilitySection