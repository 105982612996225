import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Sponsor from '../component/Sponsor/Sponsor'
import TextContent from '../component/TextContent/TextContent'
import Testimonials from "../component/Testimonials/Testimonials"
import Footer from '../component/Footer/Footer'
import { Helmet } from 'react-helmet'


function About() {
  return (
    <>
    <Helmet>
      <title>Premier Equestrian Facility | The Meadows</title>
      <meta name="description" content='Experience excellence in equestrian sports at The Meadows. From coaching programs to show journey planning, we cater to riders and horses at all levels.'/>
      
      {/* <link rel="canonical" href=""></link> */}
    </Helmet>
        <HeroBanner 
        subHeading=""
        Heading="ABOUT US"
        nextLineSpanHeading=""
        Paragraph="Where passion meets excellence in the world of equestrian sport."
        bgImg="/images/creatives/about-hero-bg.jpg"
        btn={false}
      />

<div className='left-gradient'>
      <TextContent
         subHeading=""
         heading= "WELCOME TO THE MEADOWS"
         content="As a premier full-service Equestrian Facility, we are committed to fostering the growth of sports-minded equestrians and nurturing exceptional horses. Our comprehensive coaching programs cater to competition riders at every stage of their journey, from beginners taking their first strides in the sport to advanced show riders aspiring to compete at the National and International levels."
         btn={true}
         btnText="CALL NOW"
         btnRef='/'
         btnRefTel={true}
         shiftRow={true}
         img="/images/creatives/about-text-1.png"
         reviewContent={false}
         btnImg={false}
         />

      </div>
      <div className='right-gradient'>
        
      <Sponsor/>
      <TextContent
         subHeading=""
         heading= "START YOUR SHOW JOURNEY"
         content="With a tailored plan that aligns with your unique goals and schedule. Whether you're competing for pure enjoyment or have ambitious aspirations in the Hunter, Equitation, or Jumper ring, we collaborate to develop a budget, plan, and strategy that suits your objectives."
         btn={true}
         shiftRow={false}
         btnRefTel={true}
         img="/images/creatives/about-text-2.png"
         reviewContent={false}
         btnText={"CALL NOW"}
         btnImg={false}
         dropDown={false}
         />

         </div>
      <div className='bottom-gradient'>
      <Testimonials/>
      


      <Footer
        newsletterSection={false}
      />
      </div>
    </>
  )
}

export default About