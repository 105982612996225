import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Sponsor from '../component/Sponsor/Sponsor'
import TextContent from '../component/TextContent/TextContent'
import CardCarousel from "../component/CardCarousel/CardBlog"
import Testimonials from "../component/Testimonials/Testimonials"
import Footer from '../component/Footer/Footer'
import VideoContent from '../component/VideoContent/VideoContent'
import DiscoverServices from "../component/DiscoverServices/DiscoverServices"
import DropDownContent from '../component/DropDownContent/DropDownContent'
import Contactform from '../component/Contact Form/Contactform'
import FacilitySection from '../component/FacilitySection/FacilitySection'
import { Helmet } from 'react-helmet'

const textDropDown = [
    {
      title:"Coaching & Clinicians",
      content:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
    },
    {
      title:"Rider Development Program",
      content:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
    }
  ]

function Home() {
  return (
    <div className="home-page">
      <Helmet>
      <title>Premier Equestrian Facility in King City | The Meadows Equine</title>
      <meta name="description" content='The Meadows Equine is a premier full-service equestrian facility in King City. Offering coaching programs, stunning facilities, and partnerships for riders at all levels.'/>
      
      {/* <link rel="canonical" href=""></link> */}
    </Helmet>
      <HeroBanner 
        subHeading="Welcome to"
        Heading="THE MEADOWS EQUINE"
        nextLineSpanHeading=""
        Paragraph="A premier full-service equestrian facility"
        bgImg="/images/creatives/home-hero-bg.png"
        btn={false}
      />

<div className='left-gradient'>
      <TextContent
         subHeading=""
         heading= "A FULL SERVICE EQUESTRIAN FACILITY"
         content="As a premier full-service Equestrian Facility, we are committed to fostering the growth of sports-minded equestrians and nurturing exceptional horses. Our comprehensive coaching programs cater to competition riders at every stage of their journey, from beginners taking their first strides in the sport to advanced show riders aspiring to compete at the National and International levels."
         btn={true}
         btnText="LEARN MORE"
         btnRef='/facility'
         shiftRow={true}
         img="/images/creatives/home-text-1.png"
         reviewContent={false}
         btnImg={true}
         />

      </div>
        <div className='left-gradient-upper'>
      <FacilitySection/>
      <div className='right-gradient'>
        <div style={{height:"15px"}}></div>
      <Sponsor/>
      {/* <TextContent
         subHeading="Programs"
         heading= "Unlock Your Show Potential with The Meadows Coaching Program"
         content="Embark on your show journey with a tailored plan that aligns with your unique goals and schedule. At The Meadows, we always start with the end in mind. Whether you're competing for pure enjoyment or have ambitious aspirations in the Hunter, Equitation, or Jumper ring, we collaborate to develop a budget, plan, and strategy that suits your objectives."
         btn={false}
         shiftRow={false}
         img="/images/creatives/home-text-2.png"
         reviewContent={false}
         btnImg={true}
         dropDown={true}
         dropDownList={textDropDown}
         /> */}

        
         </div>
         </div>
      <div className='bottom-gradient'>
      <Testimonials/>
      <CardCarousel
        subHeading="OUR BLOGS"
        heading="OUR POPULAR NEWS"
        content={"At The Meadows, we are very proud of our facility and the amenities that we provide. Our facility is both functional and beautiful to ride in. Considerable time and thought went into the design and construction of the entire facility. No detail has been overlooked. We carefully selected the best in modern features with both our riders and their horses in mind."}
      />


      <Footer
        newsletterSection={false}
      />
      </div>
</div>
  )
}

export default Home