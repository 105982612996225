import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'

function NotFound() {
  return (
    <>
   
        <HeroBanner
            subHeading=""
            Heading="404 "
            nextLineSpanHeading=""
            Paragraph="Not Found"
            btnText=""
            bgImg="/images/creatives/nf-hero-bg.jpg"
            btn={false}
            btnRef="/"
            bgEffect={true}
            isCheveron={false}
        />
        <Footer
            newsletterSection={false}
            />
        
    </>
  )
}

export default NotFound