import React from 'react'
import "./Sponsor.css"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";

function Sponsor() {
  return (
    <div className='sponsor-container  standard-margin-space py-1'>
      <div className='container'>
        <div className={`text-content text-center`}>
          <p className='sub-heading mb-0 justify-content-center d-flex aling-items-center gap-lg-4 gap-3'><div style={{height:"0",width:"50px",border:"1px solid #A1845D",margin:"auto 0" }}></div>OUR partners<div style={{height:"0",width:"50px",border:"1px solid #A1845D",margin:"auto 0" }}></div></p>

            <h3 className='body-heading my-2 mt-3 '>WE ARE PLEASED TO COLLABORATE WITH THE FOLLOWING ESTABLISHMENTS</h3>
            {/* <p className='body-paragraph my-1 mb-3'>Thank you to our sponsors for their continued support!</p> */}
           
        </div>
          
      <div className='py-2 '></div>
        <div className="companies-logo-box ">
        <Swiper
            spaceBetween={10}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay]}
            breakpoints={{
                200:{
                  slidesPerView: 1,
                },
                550: {
                  slidesPerView: 2,
                },
                800: {
                  slidesPerView: 3,
                },
                1000: {
                  slidesPerView: 4,
                },
              }}
            className="mx-auto"
            >
                <SwiperSlide>
                <img src="/images/icons/sponsor-logo-1.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/sponsor-logo-2.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/sponsor-logo-3.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/sponsor-logo-4.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/sponsor-logo-5.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/sponsor-logo-6.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/sponsor-logo-7.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/sponsor-logo-8.png" alt="" />
                </SwiperSlide>
                  
            </Swiper>
        </div>
        <div className='py-2'></div>
        </div>
    </div>
  )
}

export default Sponsor