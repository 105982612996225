import {useNavigate} from "react-router-dom"
 
function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.substring(0, maxLength - 3) + '...';
  }
}

function CardBox(props) {
  const navigate = useNavigate()
  
  const breifTitle = truncateText(props.title,50)
  const breifPara = truncateText(props.content,100)
  return (
    <div className="card-box"  onClick={()=>{props.redirectionFunction(props.anchor)}}>
      <div className="w-100">
        <img src={props.cardImg} alt="someImage" />
        <div className="card-text-content">
         
          <h5 className="title my-3">{breifTitle}</h5>
          <div className="body-paragraph text-center mb-4" dangerouslySetInnerHTML={{__html : breifPara}}></div>
        </div>
      </div>
       
    </div>
  )
}

export default CardBox