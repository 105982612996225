import React, { Fragment, useEffect, useState } from "react";
import HeroBanner from "../component/Common Banner Component/HeroBanner"
import IdvBlogSec from "../component/IdvBlogSec/IdvBlogSec"
import Footer from '../component/Footer/Footer'
import { useParams } from "react-router";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";


const blogInnerDummy = {
  title:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  published_date:"26 Dec 2029",
  blogs_content:[
    {
      heading:"Lorem Ipsum",
      paragraph:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s"
    },
    {
      heading:"Lorem Ipsum is simply dummy text",
      paragraph:"<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>"
    },
    {
      heading:"Lorem Ipsum is simply dummy text",
      paragraph:"<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>"
    },
  ],
  brief_paragraph:""

}


const recentBlogDumy = [
  {
    logo:"/images/creatives/popular-blog-thumbnail.jpg",
    blog_description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    slug_url:"blogs"
  },
  {
    logo:"/images/creatives/popular-blog-thumbnail.jpg",
    blog_description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    slug_url:"blogs"
  },
  {
    logo:"/images/creatives/popular-blog-thumbnail.jpg",
    blog_description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    slug_url:"blogs"
  },
]

function BlogInner() {

  const [blogsData, setBlogsData] = useState();
  const [catagory, setCatagory] = useState("Genral");
  const [recentBlogs, setRecentBlogs] = useState([]);

  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  let blogAPICalledId = false;
  let allBlogsCalled = false;

  const getAllBlogs = async () => {
    if (allBlogsCalled) return;
    allBlogsCalled = true;

    setLoading(true);
    axios
      .get(`/blogs`, {})
      .then((res) => {
        if (res.data.status === "success") {
          let Updated_recent_blogs = [];
          let blog = res.data.data.find((item) => item.slug_url == id);
          console.log("1",blog);
          setBlogsData(blog);

          res.data.data.forEach((item) => {
            Updated_recent_blogs.push({
              id: item.blog_id,
              slug_url: item.slug_url,
              logo: item.blog_image,
              blog_description: item.title,
              date: item.published_date,
            });
          });
          setRecentBlogs(Updated_recent_blogs.slice(0, 3));

          axios.get(`/api/categories/catagory/${blog.category}`).then((result)=>{
            if(result.data.status == "success")
            {
              setCatagory(result.data.data.category)
              setLoading(false)
            }
            else{
              setLoading(false)
            }
          })
        }
      })
      .catch((err) => {
        console.log("err",err);
        setBlogsData(null)
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllBlogs();
  }, [id]);


  return (
    <>
    {loading  ? (
          <div
            style={{ width: "100%", height: "100vh" }}
            className="d-flex justify-content-center align-items-center"
          >
            <Spinner
              style={{ color: "#A1845D", width: "120px", height: "120px" }}
            />
          </div>
        ) : (
          <>
            <HeroBanner 
              subHeading=""
              Heading={blogsData.title}
              smallHeading={true}
              nextLineSpanHeading=""
              Paragraph={`By: The Meadows | Published On: ${blogsData.published_date} | Categories: ${catagory}`}
              btn={false}
              bgImg="/images/creatives/blog-inner-hero-bg.jpg"
              sideLinks={false}
            />
            <IdvBlogSec
              blogsData={blogsData.blogs_content}
              text={blogsData.brief_paragraph}
              published_date={blogsData.published_date}
              recentBlogs={recentBlogs}
            />
            <Footer
              newsletterSection={false}
            />
          </>
        )}
    </>
  )
}

export default BlogInner