import React from 'react'
import "./TextContent.css"
import { useNavigate } from 'react-router-dom';
import DropDownContainer from './DropDownContainer';

function TextContent({
  heading,
  subHeading="",
  subHeadingLine=true,
  postSubHeading="",
  postHeading="",
  postContent="",
  content,
  btn=false,
  btnRefTel=false,
  btnText,
  btnRef="/",
  shiftRow=false,
  img,
  reviewContent= false,
  review,
  reviewUser,
  userImg,
  mobileTextCenter=true,
  btnImg=false,
  dropDown=false,
  dropDownList=[],
  bulletList=false,
  bulletListData=[],
  customList=false,
  customListData=[],
  btnEnd=false
}) {
  const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='body-paragraph my-1 mb-3'>{paragraph}</p>);
  const navigate = useNavigate()
  return (
    <div className='text-content-section standard-padding-space'>
      <div className='container'>
        <div className={`row d-flex ${shiftRow && "flex-row-reverse"} align-items-center gy-2`} >
          <div className={`col-lg-6 p-2 px-3 px-lg-2 d-flex align-items-center justify-content-center ${shiftRow ? "justify-content-lg-end" : "justify-content-lg-start"}`}>
            <img src={img} className='img-fluid'/>
          </div>
          <div className='col-lg-6'>
            <div className={`text-content ${mobileTextCenter && "text-center text-lg-start"} `}>
              {subHeading && <p className='sub-heading mb-0 justify-content-lg-start justify-content-center d-flex aling-items-center gap-lg-4 gap-3'>{subHeadingLine &&<div style={{height:"0",width:"50px",border:"1px solid #A1845D",margin:"auto 0" }}></div>}{subHeading}</p>}
              {/* <div className='paddLeft'> */}
              <div className='paddLef'>
                <h3 className='body-heading my-2 mt-3 '>{heading}</h3>
                <p className='sub-heading mb-3 mb-xxl-4'>{postSubHeading}</p>
                <div className=''>{paragraphs}</div>
                <h3 className='body-heading mt-3 my-xl-3 mt-xl-4'>{postHeading}</h3>
                <p className='body-paragraph my-1 mb-3'>{postContent}</p>
                

                {
                  btnRefTel ?
                  (
                    <a href='tel:905-841-9879' style={{textDecoration:"none"}}>
                      {btn && <button className='genral-btn mt-4 d-flex align-items-center gap-2 mx-auto mx-lg-0'>{btnText}{btnImg && <img src='/images/icons/genral-btn-arrow.svg'/>}</button>}
                    </a>
                  ):
                  (
                    <>
                    {btn && <button className='genral-btn mt-4 d-flex align-items-center gap-2 mx-auto mx-lg-0' onClick={()=>{navigate(btnRef)}}>{btnText}{btnImg && <img src='/images/icons/genral-btn-arrow.svg'/>}</button>}
                    </>
                  )
                }
              </div>
              {
                reviewContent &&
                <>
                  <div className='line-seprator my-2 mt-4'></div>
                  <div className='d-flex align-items-start gap-3 pt-4'>
                    <img src={userImg}/>
                    <div className='my-3'>
                      <p className='body-paragraph mb-1'>{review}</p>
                      <p className='body-paragraph mb-0'>{reviewUser}</p>
                    </div>
                  </div>
                  <button className='text-btn mt-3' onClick={()=>{navigate("/about")}}>ABOUT US</button>
                </>
              }
              {
                dropDown &&
                <>
                  {
                    dropDownList.map((ele,ind)=>{
                      return(
                        <>
                        <DropDownContainer
                          title={ele.title}
                          content={ele.content}
                        />
                        {ind < dropDownList.length-1 ? <div className='line-seprator'></div>:<></>}
                        </>
                      )
                    })
                  }
                </>
              }
              {
                bulletList &&
                <ul className='bulletList-container'>
                  {
                    bulletListData.map((ele,ind)=>{
                      return(
                          <li><p className='body-paragraph'>{ele}</p></li>
                      )
                    })
                  }
                </ul>
              }
              {
                customList &&
                <ul className='bulletList-container'>
                  {
                    customListData.map((ele,ind)=>{
                      return(
                          <li ><div dangerouslySetInnerHTML={{__html : ele}}></div></li>
                      )
                    })
                  }
                </ul>
              }
              {
                btnEnd &&
                (
                  <a href='tel:905-841-9879' style={{textDecoration:"none"}}>
                    <button className='genral-btn mt-4 d-flex align-items-center gap-2 mx-auto mx-lg-0'>{btnText}{btnImg && <img src='/images/icons/genral-btn-arrow.svg'/>}</button>
                  </a>
                )

              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextContent