import React from 'react'
import "./ScrollingTextContent.css"
import { useNavigate } from 'react-router-dom'

function TextContent({
    bgColor,
    shiftRow=false,
    heading="",
    subHeading="",
    content="",
    img,
    btn=false,
    btnText="Read More",
    btnRef="/",
    btnYellow=false

}) {
    const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='content body-paragraph mb-3'>{paragraph}</p>);
    const navigate = useNavigate()
  return (
    <div className='ScrollingTextContent-container standard-padding-space mb-4' style={{backgroundColor: bgColor}}>
        <div className='container'>
            <div className={`row d-flex ${ shiftRow ? "flex-row-reverse" : ""} align-items-center justify-content-center gy-4`}>
                <div className='col-md-6 h-100 my-auto px-4'>
                    <img src={ img} className='img-fluid' />
                </div>
                <div className='col-md-6'>
                    <div className='text-content d-flex flex-column align-items-start justify-content-center gap-lg-2 h-100 px-2 px-md-0'>
                        {subHeading == "" ? <></> :<h5 className='sub-title'>{ subHeading}</h5>}
                        <h3 className='heading body-heading'>{ heading}</h3>
                        <div className='scroll-content'>
                            <p className='content body-paragraph'>At The Meadows, we have crafted a leading training program in Ontario for ambitious child and junior riders, earning a well deserved reputation for producing top-tier, well-trained, and impeccably presented horses in the show ring.</p>
                            <p className='content body-paragraph'>Our team of expert grooms and barn staff goes above and beyond, delivering an unparalleled experience for both horses and riders. With an unwavering commitment to the highest standards of horse care, they ensure that our facilities remain immaculate, providing a welcoming environment for our clients.</p>
                            <p className='content body-paragraph'>We prioritize the health and wellness of our horses, and we are privileged to collaborate with highly skilled professionals in veterinary medicine, farriery, and other essential equine care services such as chiropractic care, physiotherapy, and massage. Our commitment to excellence extends to our team selection, as we carefully choose and employ only the most qualified individuals who share our passion for their work and are dedicated to upholding the same elevated standards of care that define The Meadows.</p>

                        </div>
                        {/* <p className='content body-paragraph'>{ paragraphs}</p> */}
                        {btn && <button className={`genral-btn ${btnYellow && "btn-y"} mt-2`} onClick={()=>{navigate(btnRef)}}>{btnText}</button>}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TextContent
