import './App.css';
import "./bootstrap.min.css"
import About from './screens/About';
import Home from './screens/Home';
import { Routes, Route } from 'react-router-dom';
import RiderDevelopment from './screens/RiderDevelopment';
import Team from './screens/Team';
import Showing from './screens/Showing';
import Facility from './screens/Facility';
import Blogs from './screens/Blogs';
import BlogInner from './screens/BlogInner';
import Contact from './screens/Contact';

import LoginScreen from "./AdminScreens/LoginScreen";
import BlogsScreen from "./AdminScreens/BlogsScreens/BlogsScreen";
import AdminScreen from "./AdminScreens/AdminScreen";
import SalesAndLease from './screens/SalesAndLease';

import ThankYou from "./screens/ThankYou"
import NotFound from "./screens/NotFound"


import LocationScreen from './screens/LocationScreens/LocationScreen';
import LocationPages from "./component/LocationPages_/LocationPages"
import PrivacyPolicy from './screens/PrivacyPolicy';


function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/team" element={<Team />} />
        <Route path="/showing" element={<Showing />} />
        <Route path="/facility" element={<Facility />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/sales-and-leasing" element={<SalesAndLease />} />
        <Route path="/rider-development" element={<RiderDevelopment />} />

        {/* <Route path="/bloginner" element={<BlogInner />} /> */}
        <Route path={`/blogs/:id`} element={<BlogInner />} />

        <Route path="/admin" element={<AdminScreen />} />
        <Route path="/admin/blogs" element={<BlogsScreen />} />
        <Route path="/login" element={<LoginScreen />} />

        
        {/* <Route path="/thank-you" element={<ThankYou />} /> */}

        <Route path="*" element={<NotFound />} />

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        
        <Route path='/location/:slug' element={<LocationPages />} />
        <Route path='/admin/location-pages' element={<LocationScreen />}/>
      </Routes>
    </div>
  );
}

export default App;
