import React from 'react'
import "./RidingSpaces.css"

function RidingSpaces() {
  return (
    <div className='RidingSpaces-container standard-padding-space'>
        <div className='container my-3'>
            <div className='text-content text-center'>
                <h3 className='body-heading' style={{color:"#A1845D"}}>Premium Outdoor and Indoor Riding Spaces</h3>
                {/* <p className='body-paragraph mx-auto' style={{color:"white",width:"90%"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the</p> */}
            </div>
            <div className='card-container mt-5'>
                <div className='row gy-3'>
                    <div className='col-lg-6'>
                        <div className='plain-card text-center'>
                            <img src='/images/creatives/facility-riding-space-1.png' className='card-img'/>
                            <div className=' p-4 '>
                                <h5 className='title mb-1'>Outdoor Ring</h5>
                                <p className='body-paragraph mb-0 mx-auto mt-3 mb-2'>Our 140’ x 240’ ring is surrounded by paddocks and trees, featuring underground drainage, automatic irrigation, and shock-free, dust-free footing. A shaded viewing cabana and various jumps enhance the riding experience.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='plain-card text-center'>
                            <img src='/images/creatives/facility-riding-space-2.png' className='card-img'/>
                            <div className=' p-4 '>
                                <h5 className='title mb-1'>Indoor Arena</h5>
                                <p className='body-paragraph mb-0 mx-auto mt-3 mb-2'>The 180’ x 80’ arena boasts European TravelRight footing, ensuring a dust-free, shock-absorbent base. Bright windows, T5 custom lighting, and ample space create a comfortable and safe environment for both horse and rider.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
  )
}

export default RidingSpaces