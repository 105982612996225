import React from 'react'
import "./OurCommitment.css"

function OurCommitment() {
  return (
    <div className='OurCommitment-container standard-padding-space'>
        <div className='container my-3'>
            <div className='text-content text-center'>
                <h3 className='body-heading'>Our Commitment</h3>
                {/* <p className='body-paragraph mx-auto' style={{width:"90%"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p> */}
            </div>
            <div className='row mt-5 gy-4'>
                <div className='col-md-6'>
                    <div className='plain-card text-center p-4 py-5'>
                        <h5 className='title mb-2'>Producing Well-Rounded Athletes</h5>
                        <p className='body-paragraph mb-0 mx-auto mt-3'>Our commitment to producing well-rounded athletes extends beyond the arena. The Meadows fosters a family-friendly and team-encompassing atmosphere, providing the ultimate learning environment and community for athletes, parents, horse owners, staff, and coaches.</p>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='plain-card text-center p-4 py-5'>
                        <h5 className='title mb-2'>All-Inclusive Full Training Program</h5>
                        <p className='body-paragraph mb-0 mx-auto mt-3'>Immerse yourself in our all-inclusive full training program, featuring a 5-day-a-week schedule. With a combination of private or semi-private lessons and professional training rides, our program is tailored to meet the needs of each equine athlete. At The Meadows, we are dedicated to nurturing the potential of both riders and their horses, fostering growth in a supportive and enriching environment.</p>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='plain-card text-center p-4 py-5'>
                        <h5 className='title mb-2'>Supportive, Team-Based Approach</h5>
                        <p className='body-paragraph mb-0 mx-auto mt-3'>At The Meadows, we believe in the strength of community. Our riders, along with their families and friends, form a supportive network. Recognizing that success in this sport requires a village, we encourage everyone to excel within our family-oriented environment.</p>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='plain-card text-center p-4 py-5'>
                        <h5 className='title mb-2'>Promoting Good Sportsmanship</h5>
                        <p className='body-paragraph mb-0 mx-auto mt-3'>We foster an atmosphere of respect, support, and good sportsmanship among teammates, opponents, coaches, and officials. Valuing the rights, feelings, and property of others, we strive to maintain a fun and healthy environment for everyone.</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
  )
}

export default OurCommitment